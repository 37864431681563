
$(document).ready(function() {
const root = document.documentElement;
const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
const marqueeContent = document.querySelector("ul.marquee-content");

var b = parseInt(localStorage.getItem('imagenumber'));
root.style.setProperty("--marquee-elements", b); // set item in local storage the lenght of buisness image and get the lenght from it to loop

for(let i=0; i<marqueeElementsDisplayed; i++) {
  marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
}
});