
$(document).ready(function() {
const hamburger = document.querySelector(".hamburger");
const navMenu = document.querySelector(".nav-menu");


hamburger.addEventListener("click", mobileMenu);

function mobileMenu() {
    hamburger.classList.toggle("active");
    navMenu.classList.toggle("active");
}



// $('.navbar-toggler').on('click', function(){
//     $(".navbar-collapse").show();
// });
// $('.navbar-collapse li').on('click', function(){
//     $(".navbar-collapse").hide();
//     $(".navbar-toggler").removeClass("active");
// });

// $('.navbar-collapse a').on('click', function(){
//     $(".navbar-collapse").collapse('hide');
//   });
});